<template>
  <b-card>
    <h1>ciao</h1>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
}
</script>

<style>

</style>
